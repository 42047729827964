import { useEffect, useState } from "react";

import { LogsQueryClient, LogsQueryResultStatus, QueryTimeInterval } from "@azure/monitor-query";
import { InteractiveBrowserCredential } from "@azure/identity";
import config from "./Config.ts";
import { resourceGroup } from "./applications.ts";

export interface FetchState<T> {
    data: T | null;
    loading: boolean;
    error: Error | null;
    reload: () => void;
}

const logsQueryClient = new LogsQueryClient(new InteractiveBrowserCredential({
    tenantId: config.tenantId,
    clientId: config.clientId,
    loginStyle: "popup"
}));

export const useQueryApplication = (query: LogsQuery): FetchState<Logline[]> => {
    const [data, setData] = useState<Logline[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    function fetchData() {
        setLoading(true);
        const result = logsQueryClient.queryWorkspace(config.logWorkspaceId, query.query, query.timeInterval);

        result.then(value => {
            if (value.status === LogsQueryResultStatus.Success) {
                const tablesFromResult = value.tables;
                const idx = tablesFromResult[0].columnDescriptors.map(c => c.name).indexOf("ResultDescription");
                const lines = tablesFromResult[0].rows.map(l => l[idx])
                    .map(l => {
                        const l1 = l as string;
                        const id = djb2Hash(l1);
                        return new Logline(id, l1);
                    });
                setData(lines)
            } else {
                console.log(`Error processing the query '${query}' - ${value.partialError}`);
                if (value.partialTables.length > 0) {
                    console.log(`This query has also returned partial data in the following table(s) - ${value.partialTables}`);
                }
            }
        })
            .catch(error => setError(error as Error))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData();
    }, [query.query, query.timeInterval]);
    return { data, loading, error, reload: fetchData };

}

export class LogsQuery {
    readonly query: string;
    readonly timeInterval: QueryTimeInterval;

    constructor(query: string, timeInterval: QueryTimeInterval) {
        this.query = query;
        this.timeInterval = timeInterval;
    }
}

export const traceIdQuery = (traceId: string, timeInterval: QueryTimeInterval): LogsQuery => new LogsQuery(
    `AppServiceConsoleLogs | where ResultDescription has '${traceId}'`,
    timeInterval
);

export const applicationLogsQuery = function (application: string, timeInterval: QueryTimeInterval) {
    const resourceId = `/subscriptions/${config.subscriptionId}/resourcegroups/${resourceGroup(application)}/providers/microsoft.web/sites/${resourceGroup(application)}-app`
    return new LogsQuery(
        `AppServiceConsoleLogs | where _ResourceId == "${resourceId}"`,
        timeInterval
    )
};

export const applicationErrorLogsQuery = function (application: string, timeInterval: QueryTimeInterval) {
    const resourceId = `/subscriptions/${config.subscriptionId}/resourcegroups/${resourceGroup(application)}/providers/microsoft.web/sites/${resourceGroup(application)}-app`
    return new LogsQuery(
        `AppServiceConsoleLogs | where _ResourceId == "${resourceId}" and (ResultDescription has_cs " ERROR " or ResultDescription has_cs "\\"ERROR\\"")`,
        timeInterval
    )
};

export class Logline {
    readonly id: string
    readonly line: string

    constructor(id: string, line: string) {
        this.id = id;
        this.line = line;
    }
}
function djb2Hash(str: string): string {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        hash = (hash * 33) ^ str.charCodeAt(i);
    }    return String(hash >>> 0); // Ensure the hash is a positive integer
}